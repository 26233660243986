<template>
  <div v-if="poslovnice.length > 1">
    <v-select
                v-model="poslovnica"
                :items="poslovnice"
                item-text="title"
                item-value="title"
                label="Narudžba za poslovnicu"
                dense
                solo
    ></v-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      timer: null,
      open_input: -1,
      NapomenaModal: false,
      item: {},
    };
  },
  //props: ['category_id'],
  computed: {
    ...mapGetters({
        poslovnice: 'users/getPoslovnice',
    }),
    poslovnica: {
      set(poslovnica) {
        this.$store.commit('users/setPoslovnica', poslovnica, { root: true })
        let item = this.poslovnice.find(i=>i.title === poslovnica)
        this.$store.commit('users/setGroup', item.grupa, { root: true })
        this.$store.dispatch('products/setSubjectPrice', {subject: poslovnica,s:false}, { root: true });
      },
      get() {
        return  this.$store.getters['users/getPoslovnica']
      },
    },
  }
};
</script>
<style scoped>
</style>
