<template>
    <v-container>
      <v-row dense>
          <v-col cols="12">
              <PoslovniceList/>
          </v-col>
          <v-col cols="12">
              <IzdvajamoList/>
          </v-col>
      </v-row>
    </v-container>
</template>
<script>
import IzdvajamoList from '../components/IzdvajamoList.vue'
import PoslovniceList from '../components/PoslovniceList.vue'
export default {
  components: { IzdvajamoList, PoslovniceList },
  computed: {
  }
};
</script>