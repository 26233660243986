<template>
  <div>
    <v-row class="mt-10">
      <v-col cols="12" class="text-center mt-4 mb-6" v-if="unseen.length">
        <router-link to="/komunikacija">
          <v-btn color="red" class="unread">
            <v-icon small color="white" class="mr-4">fas fa-envelope</v-icon>
            <template v-if="unseen.length == 1"
              >Imate jednu nepročitanu poruku</template
            >
            <template v-else-if="unseen.length > 4"
              >Imate {{ unseen.length }} nepročitanih poruka</template
            >
            <template v-else
              >Imate {{ unseen.length }} nepročitane poruke</template
            >
          </v-btn>
        </router-link>
      </v-col>
      <v-col cols="12" class="text-center" v-if="user_favs.length">
        <router-link to="/fast_order">
          <v-btn color="primary" class="text-white">
            <v-icon small color="white" class="mr-4"
              >fas fa-cart-arrow-down</v-icon
            >
            Brza narudžba
          </v-btn>
        </router-link>
      </v-col>
      <v-col
        :lg="userCredentials.narucivanje && products.length ? 8 : 12"
        :md="userCredentials.narucivanje && products.length ? 6 : 12"
        cols="12"
      >
        <div class="rounder ubuntu_b primer px-5 pt-7 partner">
          <h2>DRAGI PARTNERU</h2>
          <p class="mt-6 text-center">
            Dobro došao u sustav za naručivanje koji smo izradili kako bismo vam
            olakšali pristup našim proizvodima i pojednostavili proces
            naručivanja. Prije svega želimo napomenuti da su cijene u sustavu
            bez RABATA, dogovoreni rabat obračunavamo na računu. Kako biste
            započeli, odaberite stavku “Shop” u meniju gore lijevo i samim
            klikom na “NARUČI” i unosom količine za artikl, pridodaješ na
            narudžbenici.<br />
            Prije zaključivanja narudžbenice imat ćeš mogućnost dodati napomenu
            za svaki artikal pojedinačno, klikom na balončić u redu artikla ili
            pri dnu opću napomenu koja nije vezana za artikle.<br /><br />Uživajte
            u aplikaciji i hvala na suradnji.<br /><br />
          </p>
          <p class="text-right">
            <i><b>Vaša Mesnica Bođirković</b></i>
          </p>
        </div>
      </v-col>
      <v-col
        lg="4"
        md="6"
        cols="12"
        v-if="userCredentials.narucivanje && products.length"
      >
        <div class="header_holder text-center ma">
          <div class="rounder_top mb-2">
            <div class="card_header ubuntu_b primer">
              Izdvajamo iz ponude
            </div>
          </div>
        </div>
        <v-row>
          <template v-if="userCredentials.narucivanje && products.length === 0">
            <v-col
              v-for="(product, index) in products"
              :key="product.id + '-' + product.quantity"
              xl="6"
              lg="6"
              md="12"
              sm="12"
              cols="12"
            >
              <v-hover v-slot:default="{ hover }" close-delay="200">
                <v-card
                  :elevation="hover ? 3 : 0"
                  class="mx-auto rounder primer"
                  outlined
                >
                  <div class="naslov clearfix">
                    <div class="product_title">{{ product.title }}</div>
                    <v-img
                      v-if="product.composition.includes(1)"
                      :src="require(`@/assets/img/guda.png`)"
                      :contain="true"
                      width="35"
                    ></v-img>
                    <v-img
                      v-if="product.composition.includes(2)"
                      :src="require(`@/assets/img/jaga.png`)"
                      :contain="true"
                      width="35"
                    ></v-img>
                    <v-img
                      v-if="product.composition.includes(3)"
                      :src="require(`@/assets/img/tele.png`)"
                      :contain="true"
                      width="35"
                    ></v-img>
                    <v-img
                      v-if="product.composition.includes(9)"
                      :src="require(`@/assets/img/jagnje.png`)"
                      :contain="true"
                      width="35"
                    ></v-img>
                    <div class="product_id">{{ product.id }}</div>
                  </div>
                  <!--<div v-html="product.prod_desc" class="text-center clearfix"></div>-->
                  <div class="text-center cart-action_product">
                    <v-btn
                      v-if="product.quantity"
                      small
                      fab
                      @click="openNapomenaModal(product)"
                      class="btn_minus"
                    >
                      <v-icon small>
                        {{
                          product.napomena == '' || product.napomena == null
                            ? 'far'
                            : 'fas'
                        }}
                        fa-comment-alt
                      </v-icon>
                    </v-btn>
                    <v-row>
                      <v-col cols="6">
                        <div class="mt-1 h32 net_price text-left">
                          {{ product.parent_price | currency }} /
                          {{ product.jm }}
                          <div class="action_date">
                            Vrijedi do:
                            {{
                              product.date
                                | dateParse('YYYY-MM-DD HH:mm:ss')
                                | dateFormat('DD.MM.YYYY.')
                            }}
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="6" class="text-right">
                        <v-btn
                          v-if="!product.quantity"
                          @click="
                            addProductToCart({
                              id: product.id,
                              step: product.step,
                            })
                          "
                          small
                          color="black"
                          class="mt-1 h32 text-right add_to_cart_btn"
                        >
                          Naruči
                        </v-btn>
                        <v-chip
                          v-else
                          class="product_chip"
                          color="transparent"
                          outlined
                        >
                          <v-icon
                            class="plus-minus"
                            small
                            left
                            @click="
                              removeProductFromCart({
                                id: product.id,
                                step: product.step,
                              })
                            "
                            >fas fa-minus</v-icon
                          >
                          <v-btn
                            text
                            class="qty_no_btn"
                            @click="openInput(index)"
                            v-if="open_input !== index"
                            >{{ product.quantity }}</v-btn
                          >
                          <v-text-field
                            v-if="open_input === index"
                            :value="product.quantity"
                            class="qty_no"
                            @keyup="isNumber(product.step, product.id, $event)"
                            @blur="openInput(index)"
                          ></v-text-field>
                          <v-icon
                            class="plus-minus"
                            small
                            right
                            @click="
                              addProductToCart({
                                id: product.id,
                                step: product.step,
                              })
                            "
                            >fas fa-plus</v-icon
                          >
                        </v-chip>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-hover>
            </v-col>
          </template>
          <v-col cols="12" v-if="userCredentials.narucivanje == 0">
            <h2>Nemate autorizaciju za kupnju</h2>
          </v-col>
          <v-col
            cols="12"
            v-if="userCredentials.narucivanje && products.length == 0"
            class="text-center"
          >
            <h2>Nema proizvoda na akciji</h2>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="NapomenaModal" max-width="320">
      <v-card>
        <v-card-title class="headline">
          Napomena
          <v-icon small color="gray" @click="closeNapomenaModal()"
            >fas fa-times</v-icon
          >
        </v-card-title>
        <v-card-text max-height="400">
          <div class="napomene">
            <v-btn
              color="green"
              @click="note_add(note_o, 'np')"
              v-for="(note_o, index) in product_notes"
              :key="index"
              >{{ note_o }}</v-btn
            >
          </div>
          <v-textarea
            outlined
            v-model="napomena"
            label="Napomena"
            counter="255"
            maxlength="255"
            ref="np"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primer" @click="closeNapomenaModal()">
            Spremi napomenu
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      timer: null,
      open_input: -1,
      NapomenaModal: false,
      item: {},
    };
  },
  //props: ['category_id'],
  computed: {
    ...mapGetters({
      products: 'products/izdvajamoProducts', //i nju sam promenio
      product_view: 'getProductView',
      userLoggedIn: 'user/loggedIn',
      user_favs: 'user/userFavs',
      user_type: 'user/userType',
      userCredentials: 'user/getCredentials',
      unseen: 'messages/getUnread',
    }),
    napomena: {
      set(napomena) {
        if (napomena.length > 254) {
          this.$store.commit('message_modal', {
            title: 'Greška',
            text: 'Maksimum 255 znakova',
          });
        } else {
          this.$store.commit('cart/setNapomena', {
            napomena,
            id: this.item.id,
          });
        }
      },
      get() {
        let id = this.item.id;
        let i =
          this.$store.getters['cart/getCartItems'].find((i) => i.id === id) ||
          {};
        let napomena =
          typeof i.napomena === 'undefined' || i.napomena === null
            ? ''
            : i.napomena;
        return napomena;
      },
    },
  },
  methods: {
    ...mapActions('cart', ['addProductToCart', 'removeProductFromCart']),
    openInput(index) {
      if (this.open_input == index) {
        this.open_input = -1;
      } else {
        this.open_input = index;
      }
    },
    openNapomenaModal(item) {
      this.$store.dispatch('cart/get_product_notes', item);
      this.NapomenaModal = true;
      this.item = item;
    },
    closeNapomenaModal() {
      this.NapomenaModal = false;
    },
  },
  beforeCreate() {
    setTimeout(() => {
      this.$store.dispatch('user/getFavs', null, { root: true });
    }, 1000);
  },
};
</script>
<style scoped>
.net_price {
  position: relative;
}
.action_date {
  position: absolute;
  top: 20px;
  color: rgb(199, 42, 42);
  font-size: 0.7rem;
}
.unread {
  padding: 5px 15px !important;
  color: #fff !important;
}
.partner {
  background-image: url('/img/bgr_new.png');
  background-position: center bottom;
  padding-bottom: 80px !important;
}
@media screen and (max-width: 960px) {
  .partner {
    padding-bottom: 120px !important;
  }
}
.headline {
  position: relative;
}
.headline button {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 18px;
}
.btn_minus {
  position: absolute;
  left: -15px;
  bottom: -15px;
}
.napomene {
  overflow-y: auto;
  height: 210px;
}
</style>
